"use strict";
// noinspection JSUnusedGlobalSymbols
Object.defineProperty(exports, "__esModule", { value: true });
exports.CFUPIPayment = exports.CFCardPayment = exports.CFUPIIntentCheckoutPayment = exports.CFWebCheckoutPayment = exports.CFDropCheckoutPayment = exports.CFWebThemeBuilder = exports.CFIntentThemeBuilder = exports.CFThemeBuilder = exports.CFPaymentComponentBuilder = exports.CFPaymentModes = exports.CFUPI = exports.UPIMode = exports.SavedCard = exports.Card = exports.CFSession = exports.CFEnvironment = void 0;
var CFEnvironment;
(function (CFEnvironment) {
    CFEnvironment["PRODUCTION"] = "PRODUCTION";
    CFEnvironment["SANDBOX"] = "SANDBOX";
})(CFEnvironment = exports.CFEnvironment || (exports.CFEnvironment = {}));
var CFSession = /** @class */ (function () {
    function CFSession(sessionID, orderID, environment) {
        if (sessionID === null || sessionID.trim() === '') {
            throw new Error('sessionID cannot be empty');
        }
        if (orderID === null || orderID.trim() === '') {
            throw new Error('orderID cannot be empty');
        }
        if (environment === null || environment.trim() === '') {
            throw new Error('environment cannot be empty');
        }
        this.payment_session_id = sessionID;
        this.orderID = orderID;
        this.environment = CFEnvironment[environment];
    }
    return CFSession;
}());
exports.CFSession = CFSession;
var Card = /** @class */ (function () {
    function Card(cardNumber, cardHolderName, cardExpiryMM, cardExpiryYY, cardCvv, saveCard) {
        if (cardNumber === null || cardNumber.trim() === '') {
            throw new Error('cardNumber cannot be empty');
        }
        if (cardHolderName === null || cardHolderName.trim() === '') {
            throw new Error('cardHolderName cannot be empty');
        }
        if (cardExpiryMM === null || cardExpiryMM.trim() === '') {
            throw new Error('cardExpiryMM cannot be empty');
        }
        if (cardExpiryYY === null || cardExpiryYY.trim() === '') {
            throw new Error('cardExpiryYY cannot be empty');
        }
        if (cardCvv === null || cardCvv.trim() === '') {
            throw new Error('cardCvv cannot be empty');
        }
        this.cardNumber = cardNumber;
        this.cardHolderName = cardHolderName;
        this.cardExpiryMM = cardExpiryMM;
        this.cardExpiryYY = cardExpiryYY;
        this.cardCvv = cardCvv;
        this.saveCard = saveCard;
    }
    return Card;
}());
exports.Card = Card;
var SavedCard = /** @class */ (function () {
    function SavedCard(instrumentId, cardCvv) {
        if (cardCvv === null || cardCvv.trim() === '') {
            throw new Error('cardCvv cannot be empty');
        }
        if (instrumentId === null || instrumentId.trim() === '') {
            throw new Error('instrumentId cannot be empty');
        }
        this.instrumentId = instrumentId;
        this.cardCvv = cardCvv;
    }
    return SavedCard;
}());
exports.SavedCard = SavedCard;
var UPIMode;
(function (UPIMode) {
    UPIMode["COLLECT"] = "COLLECT";
    UPIMode["INTENT"] = "INTENT";
})(UPIMode = exports.UPIMode || (exports.UPIMode = {}));
var CFUPI = /** @class */ (function () {
    function CFUPI(mode, id) {
        if (mode === null || mode === undefined) {
            throw new Error('upi mode cannot be empty. It can be either COLLECT/INTENT');
        }
        if (id === null || id.trim() === '') {
            throw new Error('id cannot be empty');
        }
        this.mode = mode;
        this.id = id;
    }
    return CFUPI;
}());
exports.CFUPI = CFUPI;
var CFPaymentModes;
(function (CFPaymentModes) {
    CFPaymentModes["CARD"] = "CARD";
    CFPaymentModes["UPI"] = "UPI";
    CFPaymentModes["NB"] = "NB";
    CFPaymentModes["WALLET"] = "WALLET";
    CFPaymentModes["EMI"] = "EMI";
    CFPaymentModes["PAY_LATER"] = "PAY_LATER";
    CFPaymentModes["PAYPAL"] = "PAYPAL";
})(CFPaymentModes = exports.CFPaymentModes || (exports.CFPaymentModes = {}));
var CFPaymentComponentBuilder = /** @class */ (function () {
    function CFPaymentComponentBuilder() {
        this.hashset = new Set();
    }
    CFPaymentComponentBuilder.prototype.enableAllModes = function () {
        this.hashset.add(CFPaymentModes.CARD);
        this.hashset.add(CFPaymentModes.UPI);
        this.hashset.add(CFPaymentModes.NB);
        this.hashset.add(CFPaymentModes.WALLET);
        this.hashset.add(CFPaymentModes.EMI);
        this.hashset.add(CFPaymentModes.PAY_LATER);
        this.hashset.add(CFPaymentModes.PAYPAL);
    };
    CFPaymentComponentBuilder.prototype.build = function () {
        if (this.hashset.size === 0) {
            this.enableAllModes();
        }
        return {
            hashset: this.hashset
        };
    };
    CFPaymentComponentBuilder.prototype.add = function (cfPaymentModes) {
        this.hashset.add(cfPaymentModes);
        return this;
    };
    return CFPaymentComponentBuilder;
}());
exports.CFPaymentComponentBuilder = CFPaymentComponentBuilder;
var CFThemeBuilder = /** @class */ (function () {
    function CFThemeBuilder() {
        this.navigationBarBackgroundColor = '#6A3FD3';
        this.navigationBarTextColor = '#FFFFFF';
        this.buttonBackgroundColor = '#6A3FD3';
        this.buttonTextColor = '#FFFFFF';
        this.backgroundColor = '#FFFFFF';
        this.primaryTextColor = '#11385b';
        this.secondaryTextColor = '#808080';
    }
    CFThemeBuilder.prototype.setNavigationBarBackgroundColor = function (value) {
        this.navigationBarBackgroundColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setNavigationBarTextColor = function (value) {
        this.navigationBarTextColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setButtonBackgroundColor = function (value) {
        this.buttonBackgroundColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setButtonTextColor = function (value) {
        this.buttonTextColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setPrimaryTextColor = function (value) {
        this.primaryTextColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setSecondaryTextColor = function (value) {
        this.secondaryTextColor = value;
        return this;
    };
    CFThemeBuilder.prototype.setBackgroundColor = function (value) {
        this.backgroundColor = value;
        return this;
    };
    CFThemeBuilder.prototype.build = function () {
        return {
            navigationBarBackgroundColor: this.navigationBarBackgroundColor,
            navigationBarTextColor: this.navigationBarTextColor,
            buttonBackgroundColor: this.buttonBackgroundColor,
            backgroundColor: this.backgroundColor,
            buttonTextColor: this.buttonTextColor,
            primaryTextColor: this.primaryTextColor,
            secondaryTextColor: this.secondaryTextColor
        };
    };
    return CFThemeBuilder;
}());
exports.CFThemeBuilder = CFThemeBuilder;
var CFIntentThemeBuilder = /** @class */ (function () {
    function CFIntentThemeBuilder() {
        this.backgroundColor = '#FFFFFF';
        this.primaryTextColor = '#11385b';
    }
    CFIntentThemeBuilder.prototype.setBackgroundColor = function (value) {
        this.backgroundColor = value;
        return this;
    };
    CFIntentThemeBuilder.prototype.setPrimaryTextColor = function (value) {
        this.primaryTextColor = value;
        return this;
    };
    CFIntentThemeBuilder.prototype.build = function () {
        var cfTheme = new CFThemeBuilder().build();
        return {
            buttonBackgroundColor: cfTheme.buttonBackgroundColor,
            buttonTextColor: cfTheme.buttonTextColor,
            navigationBarBackgroundColor: cfTheme.navigationBarTextColor,
            navigationBarTextColor: cfTheme.navigationBarTextColor,
            secondaryTextColor: cfTheme.secondaryTextColor,
            backgroundColor: this.backgroundColor,
            primaryTextColor: this.primaryTextColor
        };
    };
    return CFIntentThemeBuilder;
}());
exports.CFIntentThemeBuilder = CFIntentThemeBuilder;
var CFWebThemeBuilder = /** @class */ (function () {
    function CFWebThemeBuilder() {
        this.navigationBarBackgroundColor = '#6A3FD3';
        this.navigationBarTextColor = '#FFFFFF';
    }
    CFWebThemeBuilder.prototype.setNavigationBarBackgroundColor = function (value) {
        this.navigationBarBackgroundColor = value;
        return this;
    };
    CFWebThemeBuilder.prototype.setNavigationBarTextColor = function (value) {
        this.navigationBarTextColor = value;
        return this;
    };
    CFWebThemeBuilder.prototype.build = function () {
        var cfTheme = new CFThemeBuilder().build();
        return {
            buttonBackgroundColor: cfTheme.buttonBackgroundColor,
            buttonTextColor: cfTheme.buttonTextColor,
            navigationBarBackgroundColor: this.navigationBarBackgroundColor,
            navigationBarTextColor: this.navigationBarBackgroundColor,
            secondaryTextColor: cfTheme.secondaryTextColor,
            backgroundColor: cfTheme.backgroundColor,
            primaryTextColor: cfTheme.buttonTextColor
        };
    };
    return CFWebThemeBuilder;
}());
exports.CFWebThemeBuilder = CFWebThemeBuilder;
var CFDropCheckoutPayment = /** @class */ (function () {
    function CFDropCheckoutPayment(session, components, theme) {
        this.components = Array.from(new CFPaymentComponentBuilder().build().hashset);
        this.theme = new CFThemeBuilder().build();
        this.session = session;
        if (components !== null) {
            this.components = Array.from(components.hashset);
        }
        if (theme !== null) {
            this.theme = theme;
        }
    }
    CFDropCheckoutPayment.prototype.getSession = function () {
        return this.session;
    };
    CFDropCheckoutPayment.prototype.getComponents = function () {
        return this.components;
    };
    CFDropCheckoutPayment.prototype.getTheme = function () {
        return this.theme;
    };
    return CFDropCheckoutPayment;
}());
exports.CFDropCheckoutPayment = CFDropCheckoutPayment;
var CFWebCheckoutPayment = /** @class */ (function () {
    function CFWebCheckoutPayment(session, theme) {
        this.theme = new CFWebThemeBuilder().build();
        this.session = session;
        if (theme !== null) {
            this.theme = theme;
        }
    }
    CFWebCheckoutPayment.prototype.getSession = function () {
        return this.session;
    };
    CFWebCheckoutPayment.prototype.getTheme = function () {
        return this.theme;
    };
    return CFWebCheckoutPayment;
}());
exports.CFWebCheckoutPayment = CFWebCheckoutPayment;
var CFUPIIntentCheckoutPayment = /** @class */ (function () {
    function CFUPIIntentCheckoutPayment(session, theme) {
        this.theme = new CFThemeBuilder().build();
        this.session = session;
        if (theme !== null) {
            this.theme = theme;
        }
    }
    CFUPIIntentCheckoutPayment.prototype.getSession = function () {
        return this.session;
    };
    CFUPIIntentCheckoutPayment.prototype.getTheme = function () {
        return this.theme;
    };
    return CFUPIIntentCheckoutPayment;
}());
exports.CFUPIIntentCheckoutPayment = CFUPIIntentCheckoutPayment;
var CFCardPayment = /** @class */ (function () {
    function CFCardPayment(session, card) {
        this.session = session;
        this.card = card;
    }
    CFCardPayment.prototype.getSession = function () {
        return this.session;
    };
    CFCardPayment.prototype.getCard = function () {
        return this.card;
    };
    return CFCardPayment;
}());
exports.CFCardPayment = CFCardPayment;
var CFUPIPayment = /** @class */ (function () {
    function CFUPIPayment(session, upi) {
        this.session = session;
        this.upi = upi;
    }
    CFUPIPayment.prototype.getSession = function () {
        return this.session;
    };
    CFUPIPayment.prototype.getUpi = function () {
        return this.upi;
    };
    return CFUPIPayment;
}());
exports.CFUPIPayment = CFUPIPayment;
